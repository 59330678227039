.driver-info {
    .circle-picker  {
      flex-wrap: unset !important;
    }
    .driver_profile_button {
      button {
        float: right;
      }
    }
  }
  
 