.customer-info {
    .icon-box {
      padding: 0 !important;
      h3 {
        font-size: 15px !important;
      }
    }
  
    .edit_order_button {
      background-color: #ffc107 ;
      color: #333C44 !important;
      float: right !important;
      margin: .6em 0;
      &:hover {
        z-index: 1 !important;
        background-color: #ffc107 !important;    
      }
      &:disabled {
        opacity: 0.35
      }
    }

    .child_div {
      display: flex;
      align-items: stretch;
      width: 100%;
      .box {
        width: 100%;
        // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, -0.9);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0), 0px 1px 0px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0.14); 
      }
    
      .box-body {
        padding-top: 0 !important;
      }
    }

    .shadow_div {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0), 0px 1px 0px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0.14);       
    }
  }
  
 