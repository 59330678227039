.page-lock {
  height: 100%;
  background: url('#{$image_path}background/1.png') no-repeat center center fixed; 
  background-size: cover;

  .lock-centered {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -65px;

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      margin-top: -75px;
    }
  }

  .lock-container {
    position: relative;
    max-width: 420px;
    margin: 0 auto;
  }

  .lock-box {
    position: absolute;
    left: 0;
    right: 0;

    .lock-user {
      background: $white;
      width: 50%;
      float: left;
      height: 50px;
      line-height: 50px;
      margin-top: 50px;
      padding: 0 20px;
      border-left-radius: $border-radius 0 0 $border-radius;
      color: $text-muted;
    }

    .lock-img img {
      position: absolute;
      border-radius: 50%;
      left: 40%;
      width: 80px;
      height: 80px;
      border: 6px solid $white;
      background: $white;

      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        left: 33%;
        width: 150px;
        height: 150px;
        border: 10px solid $white;
      }
    }

    .lock-pwd {
      background: $white;
      width: 50%;
      float: right;
      height: 50px;
      line-height: 50px;
      padding: 0 0 0 50px;
      margin-top: 50px;
      border-right-radius: 0 $border-radius $border-radius 0;
      color: theme-color("primary");
      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        padding: 0 0 0 80px;
      }

      input {
        width: 80%;
        height: 50px;
        color: $gray-700;
        border: 0;
      }

      .btn-submit {
        position: absolute;
        top: 50%;
        right: 20px;
        color: $body-color;
        .material-icons {
          line-height: 50px;
          height: 50px;
        }
      }      
    }

  }
}