.space-bar {
  padding: 3px;
  border-radius: $border-radius;
  margin-right: 5px;
}

.order-info {
  .icon-box {
    padding: 0 !important;
    h3 {
      font-size: 15px !important;
    }
  }

  .edit_order_button {
    background-color: #ffc107 ;
    color: #333c44 !important;
    float: right !important;
    margin: 1.1em 0;
    &:hover {
      z-index: 1 !important;
      background-color: #ffc107 !important;
    }
    &:disabled {
      opacity: 0.35;
    }
  }
  .edit_order_audited_collection_button {
    background-color: #ffc107;
    color: #333c44 !important;
    margin-left: 1em;
    float: right;
    margin-top: -1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    &:hover {
      z-index: 1 !important;
      background-color: #ffc107 !important;
    }
    &:disabled {
      opacity: 0.35;
    }
  }
}

.child_div {
  display: flex;
  align-items: stretch;
  width: 100%;
  .box {
    width: 100%;
  }

  .box-body {
    padding-top: 0 !important;
  }
}
