@import "variables";
section#app-container > div {
  height: 100% !important;
}
.settings_tab:active, 
.settings_tab:focus {
  color: #ffc107 !important;
}
button:focus {outline:0;}

.container-fluid{
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.map_delete_button{
  height: 24px; //27px
  padding: 2px;
  border: none;
  background-color: white;
  margin: 5px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 200px; //300px
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  /* border-radius: 2px; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.map_delete_button:hover {
  background-color: rgb(241, 239, 233) ;
}
.map_delete_point_button {
  position: absolute;
  z-index:100000;
  background: white;
  padding: 3px;
  color: #666;
  font-weight: bold;
  border: 1px solid #999;
  font-family: sans-serif;
  font-size: 12px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, .3);
  margin-top: -10px;
  margin-left: 10px;
  cursor: pointer;
}
.map_delete_point_button:hover {
  background: #eee;
}

.sidebar-footer{
   height: auto !important;
}

tbody > tr:last-child > td {
  border-bottom: 0;
}

.AppBar {
  background-color: #333C44 !important;
  color: white !important;
}

.snackbar_success div {
  background-color: #66BB6A !important
}

.snackbar_info div {
  background-color: rgb(255, 160, 0) !important  
}

.middle div {
  text-align: center
}

.support_sign-up-in {
  margin: auto;
}

.box-body.notifications {
  padding: 0 15px !important;
}

.box-body.notifications .row > div {
  width: 100% !important;
}

.notifications hr {
  margin: -1px 0px 0px 0px !important;
}

.snackbar_error div {
  background-color: red !important
}

.remove-receiver-btn{
  position: absolute !important;
  top: -15px !important;
  left: -50px !important;
}

@media (min-width: 768px) {
  .page-auth .main-body, .page-login .main-body, .page-signup .main-body, .page-forgot .main-body {
    padding-top: 0 !important;
  }
  .remove-package-btn{
    position: absolute !important;
    top: 20px !important;
    right: 0px !important;
  }
  // .remove-receiver-btn {
  //   top: -20px !important;
  //   left: 10px !important;
  // }
  .remove-receiver-btn {
    top: -20px !important;
    left: 20px !important;
}
}

@media (min-width: 1024px) {
  .dialog-min-width{
    min-width: 1010px !important;
  }
}
@media (max-width: 760px) {
  .remove-receiver-btn{
    top: -35px !important;
    left: 100px !important;
  }
}

@media (max-width: 768px) {
  .dialog-min-width {width: 90% !important;}
  .col-md-10.mobile-width, .col-md-11.mobile-width {width:90% !important;}
  .col-md-1.mobile-width, .col-md-2.mobile-width {width:10% !important;}
}

.AppBar h1 {
  color: white !important;
  font-size: 18px !important;
  line-height: 46px !important;
}

.file_upload input {
  visibility: hidden;
  width: 1px;
}

.file_upload{
  margin-top: 13px !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.imp_comment {
  font-weight: bold;
  color:red;
  text-overflow: ellipsis !important;
  max-width: 150px !important;
  cursor: pointer !important;
  white-space: pre-line
}

.break_table_cell {
  text-overflow: ellipsis !important;
  max-width: 150px !important;
  cursor: pointer !important;
  white-space: pre-line
}

.AppBar svg {
  color: white !important;
  fill: white !important;
  width: 32px !important;
}

.AppBar div {
  margin-top: 0 !important;
}

.article {
  margin-bottom: 5px;
}

.red-icon {
  color: $red !important;
}

.yellow-icon {
  color: #ffc107 !important;
}

.orange-icon {
  color: #fd7e14 !important
}

.green-icon {
  color: $green !important;
}

.grey-icon {
  color: #808080 !important;
  font-size: 20px !important;
}
.dark-grey-icon {
  color: #5F5F5F !important;
  font-size: 25px !important;
}
.action-row .material-icons {
  font-size: 20px !important;
}

#tooltip-fab {
  max-width: 150px !important;
  cursor: pointer !important;
  position: absolute;
}

#defined-tooltipo {
  width: 150px !important;
}

.action_tooltip #tooltip-fab {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.add_reciever {
 position:absolute;
 top:37px;
 left:40px;
 width:90px;
 font-size:10px;
 background-color: #00426b;
 color:#fff;
 text-align:center ;
 border-radius: 5px;
 padding: 3px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px;
  border-color: transparent transparent #00426b transparent;
  top: 43.3px; /* Adjust this value to position the arrow */
  left: 28px;
  transform: rotate(-90deg); 
}


.clear_action_tooltip button div span div{
    top: -3px;
    left: -14px;
}
#tooltip-clear{
  width:60px !important;
}
.clear_action_tooltip #tooltip-clear{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.clear_action_tooltip{
  text-align: center;
}

.small_icon {
  font-size: 15px !important;
}

.space-bar {
  padding: 3px;
  border-radius: $border-radius;
  margin-right: 5px;
}

.article-title {
  margin: .7em 0 !important;
}

.icon-box h3 {
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  margin: 0;
  color: #e2aa01;
}
.icon-box p {
  margin: 8px 0 0;
  color: #000;
}

.action_tooltip {
  text-align: right
}

.mdl-badge.mdl-badge--overlap:after {
  background-color: #ffc107;
}

.hover-row .material-icons:hover {
  opacity: 0.5;
}

.comment_icon {
  line-height: 0 !important;
}

// .ui-animate div:first-child{
//   display: grid !important;
//   margin: 0 auto !important;
//   padding-top: 200px !important;
// }

.phone {
  color: #00b500 !important;
}

.menuNotRead {
  background-color: rgba(0,0,0,0.05) !important;
}

.menuNotRead:hover {
  background-color: rgba(0,0,0,0.1) !important;
}

.pac-container {
  z-index: 2000 !important;
}

//React voice
.react-h5-audio-player {
  width: 50% !important;
  margin: auto !important;
  padding-bottom: 10px !important;
}

.volume-controls {
  display: none !important;
}

.react-h5-audio-player .flex {
  height: 45px !important;
  padding: 10px 0 6px 10px !important;
  border-radius: 5px !important;
}

.react-h5-audio-player a.toggle-play-button {
  width: 20px !important;
  padding-top: 5px !important;
  height: 20px !important;
  cursor: pointer;
}

.react-h5-audio-player .toggle-play-wrapper {
  flex: 1 0 20px !important;
}

.react-h5-audio-player i.pause-icon {
  width: 7px !important;
  height: 10px !important;
  border-left: 3px solid white !important;
  left: 5px !important;
  box-shadow: rgb(251, 86, 21) 4px 0px 0px 0px inset !important;
}

.react-h5-audio-player i.play-icon {
  border-top: 5px solid transparent !important;
  border-bottom: 5px solid transparent !important;
  border-left: 10px solid white !important;
  left: 6px !important;
  
}

.react-h5-audio-player .indicator {
  width: 10px !important;
  height: 10px !important;
  background: #ffc107 !important;
  top: 2px !important;
}

.order_report_appbar button {
  display: none !important
}

.mdl-badge[data-badge]:after {
  font-size: 50% !important;
}

.AppBar.customer_order_report > button {
  display: none !important;
}

.bold_padding_left {
  padding-left: 10px
}

.previous-button {
  background-color: #f1f1f1;
  color: black;
}

.next-button {
  background-color: #4CAF50;
  color: white;
}

.orders .box-body {
  padding: 0.25rem !important;
}

.react-phone-number-input__row
{
	/* This is done to stretch the contents of this component */
	display     : flex;
  align-items : center;
  margin-top: 38px;
}

.react-phone-number-input__phone
{
	/* The phone number input stretches to fill all empty space */
	flex : 1;

	/* The phone number input should shrink
	   to make room for the extension input */
	min-width : 0;
}

.phone_number_error input#phone_number {
  border-bottom: 2px solid red !important;
}

.text_field_disabled_read_only input {
  color: #000000DE !important;

}

.phone_number_error_msg {
  position: relative; 
  bottom: -4px;
  font-size: 12px; 
  line-height: 12px; 
  color: rgb(244, 67, 54); 
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.custom-field-label {
  line-height: 22px; 
  transform: scale(0.75) translate(0px, 22px); 
  transform-origin: left top; 
  pointer-events: none; 
  user-select: none; 
  font-size: 13px;
  color:rgba(0, 0, 0, 0.3);
}

.custom-field-value {
  appearance: textfield; 
  padding: 0px; 
  position: relative; 
  width: 100%; 
  border: none; 
  outline: none; 
  background-color: rgba(0, 0, 0, 0); 
  color: #000000DE !important;
  cursor: inherit; 
  font: inherit;
  opacity: 1; 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
  height: 100%; box-sizing: 
  border-box; margin-top: 10px;
}
.react-phone-number-input__icon
{
	/* The flag icon size is 4x3 hence the exact `width` and `height` values */
	width      : calc(0.31em * 4);
	height     : calc(0.31em * 3);

	/* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
	border     : 1px solid rgba(0, 0, 0, 0);

	/* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
	box-sizing : content-box;
}

.react-phone-number-input__icon--international
{
	/* The international icon size is square */
	/* and also has no border hence the `2 * 1px` `width` and `height` compensation */
	width  : calc(0.31em * 3 + 2 * 1px);
	height : calc(0.31em * 3 + 2 * 1px);

	/* The international icon size is square, hence the exact `padding` value */
	/* for precise alignment with `4x3` sized country flags. */
	padding-left  : calc(0.31em / 2);
	padding-right : calc(0.31em / 2);

	border : none;
}

.react-phone-number-input__error
{
	margin-left : calc(0.31em * 4 + 2 * 1px + 0.3em + 0.35em + 0.5em);
	margin-top  : calc(0.3rem);
	color       : #D30F00;
}

.react-phone-number-input__icon-image
{
	max-width  : 100%;
	max-height : 100%;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button
{
	margin             : 0 !important;
	-webkit-appearance : none !important;
	-moz-appearance    : textfield !important;
}

.react-phone-number-input__ext-input
{
	width : 3em;
}

.react-phone-number-input__ext
{
	white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input
{
	margin-left : 0.5em;
}

/* Styling native country `<select/>`. */

.react-phone-number-input__country--native
{
	position     : relative;
	align-self   : stretch;
	display      : flex;
	align-items  : center;
	margin-right : 0.5em;
}

.react-phone-number-input__country-select
{
	position : absolute;
	top      : 0;
	left     : 0;
	height   : 100%;
	width    : 100%;
	z-index  : 1;
	border   : 0;
	opacity  : 0;
	cursor   : pointer;
}

.react-phone-number-input__country-select-arrow
{
	display            : block;
	content            : '';
	width              : 0;
	height             : 0;
	margin-bottom      : 0.1em;
	margin-top         : 0.3em;
	margin-left        : 0.3em;
	border-width       : 0.35em 0.2em 0 0.2em;
	border-style       : solid;
	border-left-color  : transparent;
	border-right-color : transparent;
	color              : #B8BDC4;
	opacity            : 0.7;
	transition         : color 0.1s;
}

/* A "divider" `<option/>` for a native `<select/>`. */
.react-phone-number-input__country-select-divider
{
	font-size: 1px;
	background: black;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow
{
	color : #03B2CB;
}

/* Styling phone number input */

.react-phone-number-input__input--style {
	height        : calc(0.3rem * 6);
	outline       : none;
	border-radius : 0;
	padding       : 0;
	appearance    : none;
	border        : none;
	border-bottom : 1px solid #C5D2E0;
	transition    : border 0.1s;
	font-size     : inherit;
}

.react-phone-number-input__input--style:focus {
	border-color: #03B2CB;
}

.react-phone-number-input__input--style--disabled {
	cursor: default;
}

.react-phone-number-input__input--style--invalid,
.react-phone-number-input__input--style--invalid:focus {
	border-color: #EB2010;
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
	box-shadow: 0 0 0 1000px white inset;
}

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
	border-bottom: none;
}

.react-phone-number-label {
  position: absolute; 
  line-height: 22px; 
  top: 38px; 
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; 
  z-index: 1; 
  transform: scale(0.75) translate(0px, -28px); 
  transform-origin: left top; 
  pointer-events: none; 
  user-select: none; 
  color: rgba(0, 0, 0, 0.3); 
  font-size: 13px;
}


// Tracking Page
.status_chip span {
  padding: 0px 5px !important;
  line-height: 2 !important;
}

.status_chip {
  margin: 5px 0 -8px 5px !important;
  border-radius: 6px !important;
}

.pending_status_chip span{
  color: #ffc107 !important;
}

.pending_status_chip {
  background-color: rgba(255, 193, 7, 0.1) !important;
}

.completed_status_chip {
  background-color: rgba(34, 139, 34, 0.1) !important;
}

.completed_status_chip span{
  color: #228B22 !important;
}

.closed_status_chip {
  background-color: rgba(220, 20, 60, 0.1) !important;
}

.closed_status_chip span{
  color: #DC143C !important;
}

.contact_box {
  color: white;
  border: 1px solid #f75b5b;
  margin: 0 0 15px 0px;
  padding: 8px 5px;
  border-radius: 3px;
  background-color: #ff756ec2;
}

/*======= Map Styling ============*/
.gmnoprint a, .gmnoprint span {
  display:none;
}
.MuiTabs-indicator, 
.TabIndicator-root-80, 
.TabIndicator-colorSecondary-82 {
  background-color: #ffc107 !important; /* Custom indicator color */
}
// .gmnoprint div {
//   background:none !important;
// }
// #GMapsID div div a div img{
//   display:none;
// }