:root {
	--PhoneInput-color--focus: #ffc107;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
    display: flex;
    align-items: center;
    margin-top: 38px;
}

.PhoneInputInput {
    flex: 1;
    min-width: 0;
    background-color: transparent;
}

.PhoneInputInput {
    border: none;
    border-bottom : 1px solid rgb(224, 224, 224);
    outline: none;
    background-color: transparent;
  }
  
.PhoneInputInput:focus {
    border-color: #ffc107;
	border-bottom: 2px solid #ffc107;
}

.phone_number_error input#phone_number {
    border-bottom: 2px solid red !important;
}

.phone_number_error_msg {
    position: relative; 
    bottom: -4px;
    font-size: 12px; 
    line-height: 12px; 
    color: rgb(244, 67, 54); 
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
    box-shadow: none !important;
    background: transparent !important;
}

.PhoneInputInternationalIconPhone {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio) + 2 * var(--PhoneInputCountrySelectArrow-borderWidth));
    height: var(--PhoneInputCountryFlag-height);
    padding-left: calc(var(--PhoneInputCountrySelectArrow-width) / 2);
    padding-right: calc(var(--PhoneInputCountrySelectArrow-width) / 2);
    border: none;
}

.react-phone-number-input__error {
    margin-left: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio) + 2 * var(--PhoneInputCountrySelectArrow-borderWidth) + var(--PhoneInputCountrySelect-marginRight) + 0.5em);
    margin-top: calc(var(--PhoneInputCountrySelectArrow-width));
    color: #D30F00;
}

.PhoneInputCountryIconImg {
    max-width: 100%;
    max-height: 100%;
}

.PhoneInputInput::-webkit-inner-spin-button,
.PhoneInputInput::-webkit-outer-spin-button {
    margin: 0 !important;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}

.PhoneInputInput {
    width: 3em;
}

.PhoneInputExtension {
    white-space: nowrap;
    margin-left: 0.5em;
}

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
                inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.react-phone-number-label2 {
    position: absolute; 
    line-height: 22px; 
    top: 38px; 
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; 
    z-index: 1; 
    transform: scale(0.75) translate(0px, -28px); 
    transform-origin: left top; 
    pointer-events: none; 
    user-select: none; 
    color: rgba(0, 0, 0, 0.3);
    font-size: 13px;
}